import React from 'react';

import { Box } from '@core';
import { CTASection } from '@containers';
import { Shape, ShapeWrapper } from '@components';

import { Image } from './components';

const ServersCta = ({ data }) => (
  <ShapeWrapper
    shape={Shape.Blob6}
    wrapperProps={{
      pt: 120,
      pb: { _: 0, lg: 120 },
      top: { _: -64, md: -80 },
      mb: { _: -64, lg: -160 }, // negative margin here to compensate for spacing below which was increased by lifting component up with negative top position
    }}
    shapeProps={{
      height: 1800,
      top: 'unset',
      bottom: 0,
      left: { _: '100%', sm: '100%', lg: '75%' },
    }}
  >
    <CTASection
      data={data}
      descriptionComponent={Box}
      imageComponent={{
        mobile: ({ src }) => <Image image={src} />,
        tablet: ({ src }) => <Image image={src} />,
        desktop: ({ src }) => <Image image={src} />,
      }}
    />
  </ShapeWrapper>
);

export default ServersCta;
