import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@core';
import { Animation, Text } from '@components';

const NumberComponent = ({ count, text }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    {count && (
      <Animation.FadeIn>
        <Text.Heading2 as="span">{`${count}+`}</Text.Heading2>
      </Animation.FadeIn>
    )}
    <Text.Subheading2 fontFamily="title" fontSize={20}>
      {text}
    </Text.Subheading2>
  </Box>
);

const Description = ({ locationCount, serverCount, description }) => {
  const { t } = useTranslation();

  return (
    <Grid.Container>
      <Text.Body1 textAlign={{ _: 'center', lg: 'left' }}>{description}</Text.Body1>
      <Grid.Row justifyContent={{ _: 'center', lg: 'flex-start' }} mt={16}>
        <NumberComponent count={locationCount} text={t('servers:headerCta.locations')} />
        <Box ml={24}>
          <NumberComponent count={serverCount} text={t('servers:headerCta.servers')} />
        </Box>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Description;
